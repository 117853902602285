import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

class Conduct extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title="Event Code of Conduct" />
        <div>

          <p>human(Ethos) events are community events intended for networking, collaboration, and learning.</p>

          <p>We value the participation of each member and want all attendees to have an enjoyable and fulfilling experience. Accordingly, all attendees are expected to show respect and courtesy to other attendees and event hosts.</p>

          <p>To make clear what is expected, all attendees, speakers, organizers, and volunteers at any event are required to conform to the following Code of Conduct. Organizers will enforce this code throughout the event.</p>

          <h2 class="h2 mb-5">Code of Conduct</h2>

          <p>human(Ethos) is dedicated to providing a positive and harassment-free event experience for everyone, regardless of age, gender, sexual orientation, disability, appearance, ethnicity, religion, or nationality. We do not tolerate harassment of event participants in any form, nor do we tolerate any behavior that would reasonably lead to another event participant being made to feel unsafe, insecure, or frightened for their physical or emotional well-being. All communication should be appropriate for a professional audience including people of many different backgrounds.</p>

          <p>Examples of encouraged behavior that contributes to a positive environment include:</p>

          <ul>
            <li> Using welcoming and inclusive language</li>
            <li> Being respectful of differing viewpoints and experiences</li>
            <li> Gracefully accepting constructive criticism</li>
            <li> Focusing on what is best for everyone at the event</li>
            <li> Showing empathy towards other participants</li>
          </ul>

          <p>Unacceptable behavior includes:</p>

          <ul>
            <li> The use of sexualized language or imagery, or inappropriate physical contact, sexual attention, or advances</li>
            <li> Trolling, insulting or derogatory comments, personal or political attacks, or sustained disruption of talks or other events</li>
            <li> Deliberate intimidation, stalking, following, or harassing photography or recording</li>
            <li> Harassment of any kind, even in a joking or ironic manner</li>
            <li> Other conduct which could reasonably be considered inappropriate in a professional setting</li>
          </ul>

          <p>Be kind to others. Do not insult or put down other attendees. Behave professionally. Remember that harassment and sexist, racist, or exclusionary jokes are not appropriate at this event.</p>

          <p>Each event guest is entirely responsible for their own actions.</p>

          <p>Thank you for helping to make this a welcoming, friendly space for all.</p>

          <h3 class="h3 mb-5">Reporting an incident</h3>

          <p>If you are being harassed, notice that someone else is being harassed, or have any other concerns, please contact the organizer by email (help@human-ethos.com) or phone/text (+1 415 226 9808‬), or by speaking to another human(Ethos) team member or a security officer.</p>

          <p>Team members will be happy to help participants contact security or local law enforcement, escort to safety, or otherwise assist those experiencing harassment to feel safe for the duration of the event. We value your attendance.</p>

          <h3 class="h3 mb-5">Credit</h3>

          <p>Portions of this Code of Conduct are based on the (http://geekfeminism.wikia.com/wiki/Conference_anti-harassment/Policy) from the Geek Feminism wiki, created by the Ada Initiative and other volunteers, under a Creative Commons Zero license.</p>

        </div>
      </Layout>
    )
  }
}

export default Conduct

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
